
















import { Vue, Component, Watch } from "vue-property-decorator";

@Component({})
export default class NoChamber extends Vue {}
